

.accHeading{font-size: 17px; text-transform: uppercase;}
.accIcon{font-size: 18px;}



@media (max-width: 767px){
.accHeading{font-size: 16px;}
h2.chakra-heading.css-1h71c5k { font-size: 20px; }


}
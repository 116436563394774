.ptList.ptListALL {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 30%;
    /* margin: 0 0 0 auto; */
    position: absolute;
    left: 15px;
    z-index: 1;
}

.ptList button {
    color: #000;
    font-size: 17px;
    height: 43px;
    border-radius: 50px;
    border: 2px solid #D12C41;
    padding: 0 10px;
    margin-right: 6px;
    margin-bottom: 14px;
}

.ptList button:last-child {
    margin-right: 0;
}

.ptList button:hover {
    background: #D12C41;
    color: #fff;
}

.ptList button.activeTab {
    background: #D12C41;
    color: #fff;
}

.tag {
    position: absolute;
    top: -30px;
    right: 0px;
    background: #d12c41;
    color: #fff !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    border-radius: 7px 7px 0px 0px;
}

.btn-new {
    border: 1px solid;
    border-color: var(--chakra-colors-Red);
    color: #000;
    text-transform: uppercase;
    border-width: 2px;
    min-height: 49px;
    margin: 15px 0px 0px;
    width: 208px;
    font-size: var(--chakra-fontSizes-sm2);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}

.btn-new:hover {
    background: var(--chakra-colors-Red);
    color: #000;
}


button.-small-dropdown-button {
    background: black;
    border-radius: 0;
    height: 44px;
    outline: none;
    border: 0;
    display: flex;
    width: 30%;
    /* margin: 0 0 0 auto; */
    left: 15px;
}

button.-small-dropdown-button:hover,
button.-small-dropdown-button:active {
    background: black;
}

.-small-dropdown-button span {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}

.-small-dropdown-button svg {
    color: #fff;
    font-size: 22px;
}

.ptList {
    margin-inline-start: 0px;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.40);
    -moz-box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.40);
    box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.40);
}

.ptList button {
    color: #000;
    font-size: 20px;
    height: 43px;
    border-radius: 0;
    border: 0;
    padding: 0 18px;
    margin-right: 0;
    margin-bottom: 0px;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
}

.ptList button:last-child {
    border-bottom: 0;
}

.pricing_tab {
    margin-bottom: 50px;
}

.-small-view-body {
    margin-bottom: 30px;
    position: relative;
}

@media(max-width: 991px) {
    button.-small-dropdown-button {
        width: 40%;
    }

    .ptList.ptListALL {
        width: 40%;
    }
}

@media(max-width: 767px) {
    button.-small-dropdown-button {
        width: 55%;
    }

    .ptList.ptListALL {
        width: 55%;
    }
}

@media(max-width: 480px) {
    button.-small-dropdown-button {
        width: 100%;
        left: 5px;
    }

    .ptList.ptListALL {
        width: 100%;
        left: 5px;
    }
}
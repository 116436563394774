.teamList.ptListALL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  /* left: 15px; */
  z-index: 1;
}

/* .teamList button {
    color: #000;
    font-size: 17px;
    height: 43px;
    border-radius: 50px;
    border: 2px solid #D12C41;
    padding: 0 10px;
    margin-right: 6px;
    margin-bottom: 14px;
} */

.teamList button:last-child {
  margin-right: 0;
}

.teamList button.activeTab {
  background: #d12c41;
  color: #fff;
}

.teamList .tag {
  position: absolute;
  top: -30px;
  right: 0px;
  background: #d12c41;
  color: #fff !important;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  border-radius: 7px 7px 0px 0px;
}

button.-team-small-dropdown-button {
  background: black;
  border-radius: 0;
  height: 50px;
  outline: none;
  border: 0;
  display: flex;
  width: 100%;
}

button.-team-small-dropdown-button:hover,
button.-team-small-dropdown-button:active {
  background: black;
}

.-team-small-dropdown-button span {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

.-team-small-dropdown-button svg {
  color: #fff;
  font-size: 22px;
}

.teamList {
  margin-inline-start: 0px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 61px -29px rgba(0, 0, 0, 0.4);
}

.teamList button {
  color: #000;
  font-size: 20px;
  height: 43px;
  border-radius: 0;
  border: 0;
  padding: 0 18px;
  margin-right: 0;
  margin-bottom: 0px;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}

.teamList button:last-child {
  border-bottom: 0;
}

@media (max-width: 1024px) {
  /* button.-team-small-dropdown-button {
    width: 50%;
  } */

  /* .teamList.ptListALL {
    width: 37%;
  } */
}

@media (max-width: 767px) {
  /* button.-team-small-dropdown-button {
    width: 55%;
  } */

  /* .teamList.ptListALL {
    width: 51.5%;
  } */
}

@media (max-width: 480px) {
  /* button.-team-small-dropdown-button {
    width: 100%;
  } */

  /* .teamList.ptListALL {
    width: 90.5%;
  } */
}



.owl-carousel .owl-item img{opacity: .6; transform: scale(1, 0.9);transition: all .3s; }

.owl-carousel .owl-item.center img {opacity: .6; transform: scale(1); opacity: 1;}

.sl_section{width: 100%; overflow: hidden;}



/* .OwlCarouselAni-container .owl-item.active.center .slider-img{    
    filter: blur(0);   
    transform: scale(1.15); 
    transition: all .5s; 
    
} 
 */








 canvas.signature {
  width: 500px;
  height: 160px;
  border: 2px dotted #cccc;
  border-radius: 15px;
} 

img.signature {
  height: 70px;
  width: 200px;
}

@media(max-width:767px) { 
  canvas.signature {
    width: 100%;
  } 
}
.navbar {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 11111;
    background: transparent;
}

.navbar.is_sticky {
    position: fixed;
    /* backdrop-filter: blur(10px); */
    animation: slideDown 0.35s ease-out;
    background: #000000cc;
    width: 100%;
    z-index: 11111;
    border-bottom: 1px solid #78787833;
}

.JoinOurNewsletterBody [data-testid="form-row"] {
    display: flex;
    flex-direction: column !important;
}

.JoinOurNewsletterBody [data-testid="form-component"] {
    display: block !important;
    flex: none !important;
}

.JoinOurNewsletterBody #email_123590682 {
    padding: 0 0 0 16px !important;
}

.JoinOurNewsletterBody #email_123590682::placeholder {
    padding: 0 !important;
}
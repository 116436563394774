@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../public/fonts/Poppins-SemiBold.eot');
  src: url('../public/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Poppins-SemiBold.woff') format('woff'),
    url('../public/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('../public/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');

}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Medium.eot');
  src: url('../public/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Medium.woff2') format('woff2'),
    url('../public/fonts/Poppins-Medium.woff') format('woff'),
    url('../public/fonts/Poppins-Medium.ttf') format('truetype'),
    url('../public/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Bold.eot');
  src: url('../public/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
    url('../public/fonts/Poppins-Bold.woff') format('woff'),
    url('../public/fonts/Poppins-Bold.ttf') format('truetype'),
    url('../public/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.eot');
  src: url('../public/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Regular.woff2') format('woff2'),
    url('../public/fonts/Poppins-Regular.woff') format('woff'),
    url('../public/fonts/Poppins-Regular.ttf') format('truetype'),
    url('../public/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Light.eot');
  src: url('../public/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-Light.woff2') format('woff2'),
    url('../public/fonts/Poppins-Light.woff') format('woff'),
    url('../public/fonts/Poppins-Light.ttf') format('truetype'),
    url('../public/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-ExtraLight.eot');
  src: url('../public/fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-ExtraLight.woff2') format('woff2'),
    url('../public/fonts/Poppins-ExtraLight.woff') format('woff'),
    url('../public/fonts/Poppins-ExtraLight.ttf') format('truetype'),
    url('../public/fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* bran summer font add */
@font-face {
  font-family: 'arialnarrow';
  src: url("../public/fonts/arialnarrow.ttf");
}

@font-face {
  font-family: 'arialnarrowBold';
  src: url("../public/fonts/arialnarrow_bold.ttf");
}

/*@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: 90px; */
  position: relative;
}

body::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 91px;
  z-index: -1;
  background: #000;
  content: "";
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../public/fonts/Poppins-SemiBold.eot');
  src: url('../public/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Poppins-SemiBold.woff') format('woff'),
    url('../public/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('../public/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');

}

a:hover {
  text-decoration: none !important;
  color: #D12C41
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link:hover,
a.nav-link.active {
  color: #D12C41;
}

html,
body,
a {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

}

/* ._appdownload_section{position: relative;}
._appdownload_section:after{position: absolute;content: ""; top:-1px;left:0;width: 100%; height:50px; background-color: #fff;}
._appdownload_section:before{position: absolute;content: ""; bottom: 0;left:0;width: 100%; height:50px; background-color: #fff;} */
.shopSection {
  z-index: 1;
}

.shopSection:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: -1;
}

.bottom_img:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: -30px;
  width: 30px;
  height: 70%;
  margin: auto;
  background-color: #D12C41;
}

._togelbuttn {
  /* margin-left: 10px !important; */
  background: transparent !important;
}

/* ._togelbuttn svg {
  margin: 0px auto;
  color: #fff;
  transform: scale(1.2);
} */
.togelbuttn_inner {
  transform: scale(1.2);
  position: absolute;
  left: 60px;
  top: 0;
  z-index: 2;
}
.header_button_wrap {
  position: absolute;
  right: 50px;
  top: 0;
}

.slider p {
  position: absolute;
  right: 217px;
  bottom: 75px;
}

.inner_banner {
  position: relative;
}

.inner_banner p {
  position: absolute;
  right: 84px;
  bottom: 75px;
}

._whatsinclude_box_body,
._whatsinclude_box_body2 {
  position: relative;
}

._whatsinclude_box_body:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: black;
}

._whatsinclude_box_body2:after {
  top: -108px !important;
}

._whatsinclude_box_body ._whatsinclude_box {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-top: 0;
  border-left: 0;
}

._whatsinclude_box_body ._whatsinclude_box:last-child {
  border-right: 0;
  border-bottom: 0;
}

._whatsinclude_box_body ._whatsinclude_box:nth-child(2n) {
  border-right: 0;
}

._whatsinclude_box_body ._whatsinclude_box:nth-last-child(-n+2) {
  border-bottom: 0;
}

._simbol {
  position: relative;
  top: -13px;
}


.overlay::before,
.overlay::after {
  display: none;
}

._marque_list:before {
  content: "";
  position: absolute;
  right: 0;
  width: 5px;
  height: 2px;
  background-color: #fff;
  margin: auto;
  top: 0;
  bottom: 0;
  left: auto;
}

.css-s0rwvl[aria-invalid=true],
.css-s0rwvl[data-invalid] {
  box-shadow: none !important;
  border-bottom: 2px solid #D12C41;
}

.css-1cwfhaa:focus-visible,
.css-1cwfhaa[data-focus-visible] {
  border-color: #D12C41 !important;
  box-shadow: none !important;
  border-bottom: 2px solid #D12C41 !important;
}

.css-1ld1xx0:focus-visible,
.css-1ld1xx0[data-focus-visible] {
  border-color: #D12C41 !important;
  box-shadow: none !important;
  border-bottom: 2px solid #D12C41 !important;
}

.css-1tjaf26:focus-visible,
.css-1tjaf26[data-focus-visible] {
  z-index: 1;
  border-color: #000 !important;
  box-shadow: 0 0 0 1px #000 !important;
}

.css-11vfzny:focus-visible,
.css-11vfzny[data-focus-visible] {
  z-index: 1;
  border-color: #000 !important;
  box-shadow: 0 0 0 1px #000 !important;
}

.css-raik97:focus-visible,
.css-raik97[data-focus-visible] {
  z-index: 1;
  border-color: #000 !important;
  box-shadow: 0 0 0 1px #000 !important;
}

._form_body .css-1tjaf26:focus-visible,
.css-1tjaf26,
._form_body .css-i5b3l8>*:not(style)~*:not(style) {
  margin-top: 0 !important;
}

.chakra-accordion__button:hover {
  background: transparent !important;
}

.Accor_item .css-17mg6aq {
  border-top: 0;
  border-bottom: 1px;
}

.Accor_item .chakra-accordion__icon {
  font-size: 22px !important;
}

.Accor_item .chakra-accordion__item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Accor_item .chakra-accordion__button {
  position: relative;
}

.Accor_item .chakra-accordion__button:after {
  content: "";
  left: 0;
  top: 16px;
  width: 10px;
  height: 10px;
  background-color: #000;
  position: absolute;
  border-radius: 100%;
}

._black_acording .chakra-accordion__icon {
  font-size: 22px !important;
  color: #fff;
}

._black_acording .chakra-accordion__item {
  border-bottom: 1px solid rgba(103, 102, 102, 0.47);
}

._black_acording .chakra-accordion__button:after {
  background-color: #fff;
  top: 28px;
}

._black_acording .chakra-accordion__button {
  position: relative;
  padding: 20px 18px;
}

._black_acording.white_acording .chakra-accordion__icon {
  font-size: 22px !important;
  color: #000;
}

._black_acording.white_acording .chakra-accordion__button:after {
  background-color: #000;
  top: 28px;
}

.innerContact input:focus,
.innerContact input:focus-visible,
.innerContact select:focus,
.innerContact select:focus-visible,
.innerContact textarea:focus,
.innerContact textarea:focus-visible {
  border-color: #ccc !important;
  box-shadow: none !important;
}

.privateHireCorporate input:focus,
.privateHireCorporate input:focus-visible,
.privateHireCorporate select:focus,
.privateHireCorporate select:focus-visible,
.privateHireCorporate textarea:focus,
.privateHireCorporate textarea:focus-visible {
  border-color: #fff !important;
  box-shadow: none !important;
}

.cpContact input:focus,
.cpContact input:focus-visible,
.cpContact textarea:focus,
.cpContact textarea:focus-visible,
.cpContact Select:focus,
.cpContact Select:focus-visible {
  /* border-color: #ccc !important; */
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.menuDropdown { 
  gap: 30px;
  padding: 40px 5%;
}

/* .menuDropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  gap: 30px;
  padding: 40px 14%;
  left: 0;
  background: #000;
  width: 100%;
  text-align: left;
  visibility: hidden;
  border-top: 1px solid #787878;
  -webkit-box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
}

.deskMenu ul li:hover .menuDropdown {
  visibility: visible;
} */

.chakra-wrap.headerWrap {
  overflow: inherit;
}

.headerWrap .chakra-wrap__list.css-wah4g8 {
  display: flex;
  align-items: center;
  justify-content: center;
  --chakra-wrap-x-spacing: 0;
}
.menu-body {
  margin-top: 83px !important;
}
.dark_bg_menu {
  max-width: 100% !important;
  background: #000 !important;
}
._small-menu {
  display: none;
}

.menuDropdown a {
  padding: 8px 15px;
  text-transform: uppercase;
}

.headerWrap {
  position: relative;
  padding: 0 50px;
}



button.chakra-button.ddIBtn {
  display: none;
}

/* button.chakra-button.ddIBtn {
  display: flex;
} */

button.ddIBtn svg.chakra-icon.css-onkibi {
  width: 23px;
  /* color: #fff !important; */
}

button.ddIBtn {
  padding: 0;
  line-height: 0;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background: transparent !important;
}

.menuDdBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxAfter {
  position: relative;
  z-index: 1;
}

.boxAfter:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: -1;
}

.opAccoSec .Accor_item .chakra-accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.opAccoSec .Accor_item .chakra-accordion__button:after {
  display: none;
}

/* .marquPanel .marquee{max-width: 0 !important;} */
ul.control-dots {
  display: none;
}

input.searchInput:focus {
  border-color: #fff;
  box-shadow: none;
}

input.chakra-input.searchInput {
  padding: 0 31px 0 0;
}

input:focus-visible {
  border: 0;
  border-bottom: 2px solid #fff !important;
  box-shadow: none !important;
}


.htmlContent h2 {
  font-size: 30px;
  margin: 0 0 10px;
}

.htmlContent p {
  font-family: poppins;
  margin: 0 0 15px;
}

.htmlContent ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.htmlContent ul li {
  border-top: 1px solid lightgrey;
  padding: 10px 0px 10px 24px;
  position: relative;
  font-size: 16px;
  font-family: poppins;
}

.htmlContent ul li:after {
  width: 9px;
  height: 9px;
  position: absolute;
  content: "";
  top: 16px;
  left: 0px;
  background: rgb(209, 44, 65);
  border-radius: 50px;
}


.loaderPanel {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
  z-index: 55;
  width: 100%;
}

.catList:hover p {
  color: #D12C41
}

img.youtubeLogo {
  width: 200px;
}

.pricing-page-banner {
  height: 430px !important;
}

.summer-closing-party-banner {
  height: 600px !important;
}

.brat-summer {
  height: 500px !important;
}

.brat_summer_font {
  font-family: 'arialnarrowBold' !important;
  text-transform: lowercase !important;
}

.brat_summer_fontRegular {
  font-family: 'arialnarrow' !important;
  font-weight: 500 !important;
  text-transform: lowercase !important;
}

.brat_summer_fontRegular_btn {
  text-transform: lowercase !important;
  font-size: 24px !important;
  background-color: #8ace03 !important;
  border: 2px solid #8ace03 !important;
}

.brat_summer_fontRegular_btn:hover {
  color: #fff !important;
}

._blogdetails {
  padding-bottom: 90px;
}

._blogdetails p a {
  text-decoration: underline;
}

.box .heading {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.box .subheading {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.box:hover .heading {
  opacity: 0;
  display: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.box:hover .subheading {
  opacity: 1;
  display: flex;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}



.icon-box-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-direction: row;
}

.boxoverlay {
  position: relative;
}

.boxoverlay:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.no-overlay .boxoverlay:after {
  display: none;
}

.selector {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  height: 400px;
}

/* CorporateWellnessCircle */
.CorporateWellnessCircle.css-14hcmql {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CorporateWellnessCircle .css-ol3i12 {
  stroke: #edebe9;
  stroke-width: 1;
}

.CorporateWellnessCircle .css-hvagwd {
  stroke: #D9395E;
  stroke-width: 5px;
}

.CorporateWellnessCircle.CorporateCircle_color1 .css-hvagwd {
  stroke: #FF4334;
}

.CorporateWellnessCircle.CorporateCircle_color2 .css-hvagwd {
  stroke: #f59f99;
}

/* UnbrokenDay8 rai video */

.UnbrokenDay8_rai_video {
  width: 55%;
}

@media(max-width:1680px) {
  .pricing-page-banner {
    height: 430px !important;
  }

  .summer-closing-party-banner {
    height: 450px !important;
  }

  .menuDropdown {
    padding: 30px 8%;
    gap: 25px;
  }
}

@media(max-width:1366px) {
  /* .pricing-page-banner {
    height: 320px !important;
  } */

  .togelbuttn_inner {
    left: 50px;
  }

  .menuDropdown {
    padding: 30px 6%;
    gap: 20px;
  }

  .summer-closing-party-banner {
    height: 400px !important;
  }

  .brat-summer {
    height: 450px !important;
  }

  /* .menu-body {
    padding-left: 20px !important;
  } */

  .menu-body li {
    font-size: 16px !important;
  }

  .unbroken_img1 {
    width: 70%;
  }
}


@media(max-width:1199px) {
  /* .pricing-page-banner {
    height: 250px !important;
  } */

  .togelbuttn_inner {
    left: 25px;
  }

  .menuDropdown {
    gap: 15px;
  }

  .brat-summer {
    height: 380px !important;
  }

  .inner_banner p {
    position: absolute;
    right: 20px;
  }

  ._price {
    font-size: 60px !important;
  }

  .menu-body {
    padding-left: 0px !important;
  }

  .menu-body li {
    font-size: 14px !important;
  }

  .UnbrokenDay8_rai_video {
    width: 70%;
  }
}

@media(max-width:991px) {

  /*  */
  /* .menu-body {
    padding-top: 80px !important;
    height: 500px;
    overflow-y: scroll;
  } */

  /* .dark_bg_menu {
    max-width: 100% !important;
    background: #000 !important;
  } */

  body::after {
    height: 71px;
  }

  .dark_bg_menu ul li {
    margin: 0;
    padding: 0;
  }

  .dark_bg_menu ul li a {
    /* color: #fff; */
    padding: 4px 0;
    display: inline-block;
    font-size: 23px;
  }

  .dark_bg_menu ul li a.nav-link.active {
    color: #D12C41;
  }

  ._small-menu {
    display: block;
  }

  /* .dark_bg_menu .chakra-modal__body {
      display: flex;
      align-items: center;
    } */

  .dark_bg_menu svg.chakra-icon.css-onkibi {
    color: #ccc;
  }

  .chakra-stack.main_navigation.mobileDropdown {
    width: 100%;
  }

  /* .mobileDropdown .menuDropdown {
    position: relative;
    visibility: visible;
    text-align: left;
    padding: 0 15px;
    display: none;
    gap: 0;
  }

  .mobileDropdown .menuDropdown.open {
    display: flex;
  } */

  /*  */
  .pricing-page-banner {
    height: 420px !important;
  }

  .summer-closing-party-banner {
    height: 340px !important;
  }

  .brat-summer {
    height: 280px !important;
  }

  ._appdownload_section:after {
    height: 20px;
  }

  ._appdownload_section:before {
    height: 20px;
  }

  /* body {
    padding-top: 70px;
  } */

  ._whatsinclude_box_body:after {
    display: none;
  }

  ._whatsinclude_box_body ._whatsinclude_box {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  ._whatsinclude_box_body ._whatsinclude_box:nth-last-child(-n+2) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  ._whatsinclude_box_body ._whatsinclude_box:last-child {
    border-bottom: 0;
  }

  /*  */
  .CorporateWellnessCircle .css-6i6myx {
    width: 370px;
    height: 370px;
  }

  .UnbrokenDay8_rai_video {
    width: 80%;
  }
}

@media(max-width:767px) {

  .summer-closing-party-banner {
    height: 700px !important;
  }

  .brat-summer {
    height: 767px !important;
  }
  .dark_bg_menu ul li {
    padding: 20px 0 0;
  }
  .dark_bg_menu ul li a {
    font-size: 20px;
  }

  img.youtubeLogo {
    width: 28%;
  }

  .pricing-page-banner {
    height: 600px !important;
  }


  button.chakra-button.ddIBtn {
    display: flex;
  }

  /* button.ddIBtn svg.chakra-icon.css-onkibi {
    width: 23px;
    color: #fff !important;
  } */

  button.ddIBtn {
    padding: 0;
    line-height: 0;
    height: 25px;
    width: 25px;
    min-width: 25px;
    background: transparent !important;
  }

  .chakra-stack.main_navigation.mobileDropdown {
    width: 100%;
  }

  .menuDdBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menuDropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    gap: 30px;
    padding: 40px 14%;
    left: 0;
    background: #000;
    width: 100%;
    text-align: left;
    visibility: hidden;
    /* border-top: 1px solid #787878; */
    -webkit-box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 49px 24px -30px rgba(0, 0, 0, 0.2);
  }
  
  .deskMenu ul li:hover .menuDropdown {
    visibility: visible;
  }

  .mobileDropdown .menuDropdown {
    position: relative;
    visibility: visible;
    text-align: left;
    padding: 0 15px;
    display: none;
  }

  .mobileDropdown .menuDropdown.open {
    display: flex;
  }
  
  ._appdownload_section:after {
    height: 80px;
  }

  ._appdownload_section:before {
    display: none;
  }

  .css-1g2419::before {
    width: 300px !important;
    display: none !important;
  }

  .css-1g2419::after {
    width: 300px !important;
    display: none !important;
  }

  .shop_now {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 30px 25px 35px !important;
    width: 70%;
  }

  .headerWrap {
    padding: 0;
  }

  /* .dark_bg_menu {
    max-width: 100% !important;
    background: #000 !important;
  }

  .dark_bg_menu ul li {
    margin: 0;
    padding: 0;
  }

  .dark_bg_menu ul li a {
    color: #fff;
    padding: 4px 0;
    display: inline-block;
    font-size: 23px;
  }

  .dark_bg_menu ul li a.nav-link.active {
    color: #D12C41;
  }

  .dark_bg_menu .chakra-modal__body {
    display: flex;
    align-items: center;
  }

  .dark_bg_menu svg.chakra-icon.css-onkibi {
    color: #ccc;
  } */

  .cfBoxRes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .inner_banner p {
    right: 0;
    left: 0;
    text-align: center;
  }


  .shop_now {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 30px 25px 35px !important;
    width: 70%;
  }


  /* .dark_bg_menu {
    max-width: 100% !important;
    background: #000 !important;
  } */

  /* .dark_bg_menu ul li {
    margin: 0;
    padding: 0;
    padding: 10px 0px;
  }

  .dark_bg_menu ul li a {
    color: #fff;
    padding: 4px 0;
    display: inline-block;
    font-size: 23px;
  }

  .dark_bg_menu ul li a.nav-link.active {
    color: #D12C41;
  } */

  /* .dark_bg_menu .chakra-modal__body {
    display: flex;
    align-items: center;
  } */

  /* .dark_bg_menu svg.chakra-icon.css-onkibi {
    color: #ccc;
  } */

  .cfBoxRes {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .inner_banner p {
    right: 28px;
    left: 0;
    text-align: right;
    bottom: 30px;
  }


  ._togelbuttn {
    margin: 0px !important;
    margin-left: 10px !important;
  }

  .-heading-caption {
    width: 80%;
  }

  .Download_APP {
    min-width: auto !important;
    width: auto !important;
    padding: 0px 12px !important;
  }

  ._small-menu .Download_APP {
    font-size: 16px !important;
    width: 100% !important;
    margin-top: 30px;
  }

  /* .menu-body {
    height: 500px;
    overflow-y: scroll;
  } */

  /*  */
  .CorporateWellnessCircle .css-6i6myx {
    width: 320px;
    height: 320px;
  }

  .UnbrokenDay8_rai_video {
    width: 55%;
  }
}

@media(max-width:640px) {
  .pricing-page-banner {
    height: 540px !important;
  }

  .firstTimersSvg {
    width: 50px;
  }

  .UnbrokenDay8_rai_video {
    width: 60%;
  }
}

@media(max-width:480px) {
  ._logo a {
    font-size: 30px;
  }
  .dark_bg_menu ul li a {
    font-size: 16px;
  }

  .Download_APP {
    min-height: 40px !important;
    line-height: 40px !important;
    font-size: 12px !important;
  }

  .-hader {
    flex-direction: row;
  }

  .inner_banner p {
    right: 0;
    left: 0;
    text-align: center;
    bottom: 30px;
  }

  /* .pricing-page-banner {
    height: 500px !important;
  } */

  .brat-summer {
    height: 500px !important;
  }

  .UnbrokenDay8_rai_video {
    width: 80%;
  }
}

@media(max-width:400px) {
  ._logo a {
    font-size: 30px;
  }

  .-heading {
    width: 200px !important;
  }

  /* .logo-section {
    max-width: 22% !important;
  } */

  /* .pricing-page-banner {
    height: 400px !important;
  } */

  .unbroken_banner_btnWrap {
    flex-direction: column;
  }

  .unbroken_img1 {
    width: 100%;
  }

  /*  */
  .CorporateWellnessCircle .css-6i6myx {
    width: 260px;
    height: 260px;
  }

  .UnbrokenDay8_rai_video {
    width: 100%;
  }
}

/*  */
select#field-\:r3\: option {
  color: black;
}

/* navigation style */
.menuDropdown.css-0 {
  font-style: italic;
}

/* iframe css */
input {
  width: 100%;
  height: 50px;
  border: 1px solid #e2e8f0;
  padding: 10px !important;
}

::placeholder {
  padding: 10px;
  font-size: 16px;
  color: black;
}

input[ type=checkbox] {
  width: auto;
  height: auto;
}

select {
  width: 100%;
  height: 50px;
  border: 1px solid #e2e8f0;
  font-size: 16px;
  padding-left: 10px !important;
  font-family: 'poppins';
}

div#hc-personal-information {
  font-size: 30px;
}

.hc-registration-field.hc-birth-date label {
  font-size: 110% !important;
}

input#hc-register {
  font-size: 16px;
}

input#registrations_liability_release {
  margin-top: 20px;
  width: auto;
  margin-right: 10px !important;
  height: 13px !important;
}

/* rechapta */
.privateHireCorporate .captcha,
.innerContact .captcha {
  -webkit-transform: scale(0.77);
  -webkit-transform-origin: 0 0;
}

.OnlineApplication .captcha {
  -webkit-transform: scale(0.77);
  -webkit-transform-origin: 0 0;
}

/* modal */
section.chakra-modal__content {
  background: transparent;
}

.hc-privacy-footer {
  display: flex;
  gap: 245px;
}

table.hc_info.stacked {
  width: 100%;
}

button.chakra-modal__close-btn.css-1x4u0zg {
  color: white;
}

/* video modal */
@media(max-width: 767px) {
  iframe.bannr_vdo {
    height: 275px;
  }
}

.css-9fgtzh {
  text-align: center;
}

textarea#field-\:rv\: {
  padding: 9px;
}

/* temporarliy */
@media(max-width: 767px) {
  .temlanding .temlanding-img {
    margin: 0 auto;
  }
}

@media(max-width: 767px) {
  .summer-closing .summerimage {
    margin: 0 auto;
  }
}

.CookieConsent div {
  display: inline-flex;
  flex: initial !important;
}

.CookieConsent button:hover {
  background: #fff !important;
  color: #D12C41 !important;
}

.tab[aria-selected=true],
.tab[data-selected] {
  color: #fff !important;
  background: #F06776 !important;
}

@media(max-width:991px) {
  .scroll-view {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    justify-content: flex-start !important;
  }

  .scroll-view::-webkit-scrollbar {
    width: 0;
  }
}

.strokeNumber {
  -webkit-text-stroke: 1px #D12C41;
  color: white !important;
}

.leftHeading {
  font-weight: 600;
}